import React from 'react';

const NotFound = () => {
    return (
        <div className="font-pretendard flex min-h-full h-screen flex-col bg-white pb-12 pt-16">
            <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-6 lg:px-8">
                <div className="flex flex-shrink-0 justify-center">
                    <a href="/" className="inline-flex">
                        <div className='mcs-logo-black'> </div>
                    </a>
                </div>
                <div className="py-8">
                    <div className="text-center">
                        <p className="text-xl font-semibold text-sky-950/90">404</p>
                        <h1 className="mt-2 text-3xl font-bold tracking-tight text-sky-950/90 sm:text-4xl">페이지를 찾을 수 없습니다</h1>
                        <p className="mt-6 text-base text-gray-500">
                            페이지 주소를 잘못 입력하셨거나, <br />
                            요청하신 페이지가 변경 혹은 삭제되었습니다. <br /> <br />
                            서비스 이용에 불편을 드려 죄송합니다.
                        </p>
                        <div className="mt-6">
                            <a href="/" className="text-base font-medium text-sky-950/90 hover:text-sky-700">
                                홈페이지 돌아가기
                                <span aria-hidden="true"> &rarr;</span>
                            </a>
                        </div>
                    </div>
                </div>
            </main>
            <footer className="mx-auto w-full max-w-7xl flex-shrink-0 px-6 lg:px-8">
                <nav className="flex justify-center space-x-4">
                    <a href="http://pf.kakao.com/_xmhdDxj/chat" className="text-sm font-medium text-gray-500 hover:text-gray-600">
                        디앤에프어패럴 문의하기
                    </a>
                    <span className="inline-block border-l border-gray-300" aria-hidden="true" />
                    <a href="https://instagram.com/dnf_apparel" className="text-sm font-medium text-gray-500 hover:text-gray-600">
                        인스타그램
                    </a>
                </nav>
            </footer>
        </div>
    );
};
  
export default NotFound;