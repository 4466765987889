import React, { Fragment, useState, useEffect, useRef } from 'react';
import SwiperCore, { Autoplay, EffectFade } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import 'swiper/css/autoplay';
import "swiper/css/pagination";
import "swiper/css";
import '../src/index.css';


import { Pagination } from "swiper";

// UI 컴포넌트 : tailwind css
import { Disclosure } from '@headlessui/react'
import { Transition } from '@headlessui/react'

// 아이콘 불러오기 tailwind css
import {
  MinusSmallIcon as OutlineMinusSmallIcon,
  PlusSmallIcon as OutlinePlusSmallIcon,
  ChevronDoubleDownIcon as OutlineChevronDoubleDownIcon,
  ChevronDoubleUpIcon as OutlineChevronDoubleUpIcon,
  CheckCircleIcon as OutlineCheckCircleIcon,
} from '@heroicons/react/24/outline'

const features = [
  {
    icon: '../static/img/icon/professional_icon.png',
    engtitle: 'Professional',
    title: '스포츠 의류 전문 오직 한길',
    description:
    <span>
      발레복, 요가복, 폴웨어, 수영복, 리조트룩, 비치웨어 등 스포츠 의류 전문 어패럴 회사로 오랜 기간 여러 브랜드들과 작업하며 신규 브랜드 런칭부터 시즌 전개까지 함께하고 있습니다.
    </span>,
  },
  {
    icon: '../static/img/icon/proficiency_icon.png',
    engtitle: 'Proficiency',
    title: '높은 완성 퀄리티',
    description:
    <span>
      수년간 축적한 스포츠 의류 전문 기술자들의 숙련도, 브랜드들과의 샘플 개발 및 본 생산 기술력 그리고 스마트공장 관리 시스템은 고객들에게 높은 퀄리티를 제공하고 있습니다.
    </span>,
  },
  {
    icon: '../static/img/icon/cooperation_icon.png',
    engtitle: 'Cooperation',
    title: '다양한 협력 업체',
    description:
    <span>
      샘플사, 패턴사, 봉제 공장까지 20여 곳의 협력 업체를 두고 있어, 패턴, 생산, 납품 모든 과정을 공장 캐파의 제약 없이 관리합니다.
    </span>,
  },
  {
    icon: '../static/img/icon/communication_icon.png',
    engtitle: 'Communication',
    title: '원만한 소통과 체계적 관리',
    description:
    <span>
      10년 이상 의류 브랜드 생산관리 경력의 젊은 관리자들이 노션, 구글 등 다양한 커뮤니케이션 툴로 고객과 소통합니다.
    </span>,
  },
  
]


const photo = [
  {
    id: 1,
    imageUrl:
      '../static/img/gallery_img/gallery_img1.jpg',
  },
  {
    id: 2,
    imageUrl:
      '../static/img/gallery_img/gallery_img2.jpg',
  },
  {
    id: 3,
    imageUrl:
      '../static/img/gallery_img/gallery_img3.jpg',
  },
];

const photo2 = [
  {
    id: 4,
    imageUrl:
      '../static/img/gallery_img/gallery_img4.jpg?ver=2',
  },
  {
    id: 5,
    imageUrl:
      '../static/img/gallery_img/gallery_img5.jpg?ver=2',
  },
  {
    id: 6,
    imageUrl:
      '../static/img/gallery_img/gallery_img6.jpg?ver=2',
  },
  {
    id: 7,
    imageUrl:
      '../static/img/gallery_img/gallery_img7.jpg',
  },
  {
    id: 8,
    imageUrl:
      '../static/img/gallery_img/gallery_img8.jpg?ver=2',
  },
  {
    id: 9,
    imageUrl:
      '../static/img/gallery_img/gallery_img9.jpg',
  },
];


const faqs = [
  {
    question: "생산 가능한 의류의 종류를 알고 싶습니다.",
    answer:
    <span>
      무용복, 요가, 필라테스, 스포츠 에슬레져류 등의 특수복 전문으로 스판사 위주의 원단을 작업합니다.
    </span>
  },
  {
    question: "방문 상담을 꼭 해야 하나요?",
    answer:
    <span>
      샘플 실물 확인 후 상담 하는것이 가장 정확하겠지만 유선, 카카오톡채널을 통한 이미지 소통으로 최대한 친절하고 정확한 상담을 진행하겠습니다.
    </span>
  },
  {
    question: "작업 의뢰 시, 작업지시서가 꼭 있어야 하나요?",
    answer:
    <span>
      작업지시서 없이 샘플만 있어도 상담을 통해 작업 가능하도록 도와드리고 있습니다.
    </span>
  },
  {
    question: "아직 샘플을 만들지 않았는데, 공임 단가를 알 수 있나요?",
    answer:
      <span>
        불가합니다. 최종 샘플과 생산수량이 확정되어야 공임비 측정이 가능합니다.
      </span>
  },
  {
    question: "최소 생산 수량(MOQ)은 어떻게 되나요?",
    answer:
    <span>
      최소 생산 수량(MOQ)은 60장입니다. 생산 수량에 따라 공임비는 달라집니다.
    </span>
  },
  {
    question: "생산 후 포장은 가능한가요?",
    answer:
    <span> 
      개별 포장 장당 500원의 비용이 발생하고, 원하지 않으면 박스 일괄 발송 해드립니다.
    </span>
  },
  {
    question: "결제 방식은?",
    answer:
    <span>
      작업 전 50% 선입금, 생산 완료 출고 후 잔금 결제 해주시면 됩니다.
    </span>
  },
  {
    question: "작업 진행 형태는 어떻게 되나요?",
    answer:
    <span>
      임가공 작업을 진행 하며 원/부자재는 공급을 해주셔야 합니다.
    </span>
  },
  {
    question: "패턴은 따로 준비해야 하나요?",
    answer:
    <span>
      샘플, 패턴 작업 모두 가능하기에 따로 패턴을 준비하지않으셔도 됩니다.
    </span>
  },

  
]


export default function Main() {

  const [show, setShow] = useState(true)

  const [scrollPosition, setScrollPosition] = useState(0);

  const updateScroll = () => {
    setScrollPosition(window.scrollY || document.documentElement.scrollTop);
  };

  useEffect(() => {
    window.addEventListener("scroll", updateScroll);
  }, []);

  function handleSelectChange(event) {
    window.location.href = event.target.value;
  }

  // About us 이미지 영역 확장 이벤트
  const dnfPhotoEvent = useRef(null);
  // 버튼 클릭시 아이콘 변경 이벤트
  const [isAccepted, setIsAccepted] = useState(false);
  const icon = isAccepted ? <OutlineChevronDoubleUpIcon className="w-4 h-4" /> : <OutlineChevronDoubleDownIcon className="w-4 h-4" />;

  // 갤러리 + 버튼 이벤트
  const addGalleryArea = () => {
    setIsAccepted(!isAccepted);
    const dnfPhotoCheck = dnfPhotoEvent.current;
    
    if (dnfPhotoCheck.classList.contains('hidden')) {
      dnfPhotoCheck.classList.remove('hidden');
    }else{
      dnfPhotoCheck.classList.add('hidden');
    }

  }

  // dnf 포트폴리오 슬라이드 영역
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + "</span>";
    },
  };

  // 메일주소 url 복사 기능
  const url = "sghdancefarm@gmail.com";
  const dnfMailCopyEvent = useRef(null);

  function  mail_copy_event() {
    const input = document.createElement("input");
    input.setAttribute("value", url);
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);

    const dnfMailCopy = dnfMailCopyEvent.current;
    
    
    if (dnfMailCopy.classList.contains('hidden')) {
      dnfMailCopy.classList.remove('hidden');
      setTimeout(() => {
        dnfMailCopy.classList.add('hidden');
      }, 3000);
    }

  }

  return (

    <div className="font-pretendard bg-white">
      
      <div className="relative flex max-h-screen">
        
        {/* header_logo */}
        <div aria-hidden="true" className="absolute inset-0 overflow-hidden">
          <nav className="relative z-10">
            <div className="mx-auto max-w-screen-2xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 items-center justify-between">
                  <div className="lg:flex lg:flex-1 lg:items-center">
                    <span className="sr-only">DNF_apparel</span>
                    <div className="dnf-logo mt-8" alt="dnfLogo"></div>
                  </div>
                </div>
            </div>
          </nav>
        </div>

        {/* main_slider */}
        <Swiper
          spaceBetween={10}
          slidesPerView={1}
          centeredSlides={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          effect="fade"
        >
            
          <SwiperSlide>
            <div className="bg-gray-200 h-screen flex justify-center items-center">
              <img src= {process.env.PUBLIC_URL + '/static/img/main_img/dnf_main_img1.jpg'} alt="main_img1" className="w-full object-cover object-center min-h-screen" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className=" bg-gray-200 h-screen flex justify-center items-center">
                <img src= {process.env.PUBLIC_URL + '/static/img/main_img/dnf_main_img2.jpg'} alt="main_img2" className="w-full object-cover object-center  min-h-screen" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="bg-gray-200 h-screen flex justify-center items-center">
              <img src= {process.env.PUBLIC_URL + '/static/img/main_img/dnf_main_img3.jpg'} alt="main_img3" className="w-full object-cover object-center min-h-screen" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="bg-gray-200 h-screen flex justify-center items-center">
              <img src= {process.env.PUBLIC_URL + '/static/img/main_img/dnf_main_img4.jpg'} alt="main_img4" className="w-full object-cover object-center min-h-screen" />
            </div>
          </SwiperSlide>
        </Swiper>

      </div>
        
      {/* Main_contents1 */}
      <div className="bg-white mx-auto max-w-7xl px-3 py-16 sm:py-32 lg:px-4">
        <div className="mx-auto max-w-xl text-center">
          <img className='aspect-[21/9]' src= {process.env.PUBLIC_URL + '/static/img/dnf_content_logo.png'}></img>
        </div>
        <div className="mx-auto mt-12 max-w-7xl sm:mt-20 lg:mt-18 lg:max-w-7xl">
          <dl className="grid max-w-5xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.title} className="relative flex flex-wrap p-2">
                <div className="p-1 w-full sm:w-1/3 flex items-center justify-start sm:justify-center">
                  <img src={feature.icon} className="h-16 w-16 sm:h-20 sm:w-20" />
                </div>
                <div className='p-1 w-full sm:w-2/3'>
                  <dt className="text-sm text-sky-950/90">{feature.engtitle}</dt>
                  <dd className="mt-1 text-2xl font-bold leading-7 text-sky-950/90">{feature.title}</dd>
                  <dd className="mt-2 text-base leading-7 text-gray-600 lg:tracking-normal">{feature.description}</dd>
                </div>
              </div>
            ))}
          </dl>
        </div>
      </div>

      {/* Main_contents2 */}
      <div className="bg-sky-950/90 w-full relative py-16 px-3 sm:py-20 lg:px-4">
        <div className="mx-auto max-w-7xl text-left">
          <h2 className="text-3xl font-bold text-white pb-8 sm:text-4xl">PROCESS</h2>
        </div>
        <div className="mx-auto relative z-10 max-w-7xl">
            <img src= {process.env.PUBLIC_URL + '/static/img/content_img/dnf_process_img.png'} alt="dnf_process_img" className="hidden w-full object-contain object-center p-4 sm:block" />
            <img src= {process.env.PUBLIC_URL + '/static/img/content_img/dnf_process_img_mb.png'} alt="dnf_process_img" className="w-full object-contain object-center p-4 sm:hidden" />
        </div>
        <img src= {process.env.PUBLIC_URL + '/static/img/content_img/dnf_back_logo.png'} alt="dnf_contents2_logo" className="w-8/12 absolute z-0 bottom-8 left-[-15px] object-contain opacity-80 sm:left-auto sm:right-8 sm:w-1/3" />
      </div>

      {/* dnf_photo */}
      <div className="bg-white mx-auto max-w-7xl py-16 px-2 sm:py-32 lg:px-4">

        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold text-sky-950/90 sm:text-4xl">About us </h2>
        </div>

        <div className="mt-8 flex justify-center">
          <img src= {process.env.PUBLIC_URL + '/static/img/making3.gif'} alt="making_gif" className="aspect-[16/9] mx-auto rounded-lg sm:max-w-2xl sm:aspect-[2/1] lg:aspect-[16/9]" />
        </div>

        <div className="mx-auto mt-8 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-8 sm:mt-16 sm:gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {photo.map((post) => (
            <article key={post.id} className="flex flex-col items-start justify-between">
              <div className="relative w-full hover:scale-105">
                <img
                  src={post.imageUrl}
                  alt="dnf_gallery_photo"
                  className="aspect-[16/9] w-full rounded-lg bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                />
                <div className="absolute inset-0 rounded-lg ring-1 ring-inset ring-gray-900/10" />
              </div>
            </article>
          ))}
        </div>
        <div ref={dnfPhotoEvent} className="hidden mx-auto mt-8 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-8 sm:mt-16 sm:gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {photo2.map((post) => (
            <article key={post.id} className="flex flex-col items-start justify-between">
              <div className="relative w-full hover:scale-105">
                <img
                  src={post.imageUrl}
                  alt="dnf_gallery_photo"
                  className="aspect-[16/9] w-full rounded-lg bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                />
                <div className="absolute inset-0 rounded-lg ring-1 ring-inset ring-gray-900/10" />
              </div>
            </article>
          ))}
        </div>
        <div className="flex justify-center items-center mt-6 w-full ">
          <button className='bg-sky-950/90 rounded-full cursor-pointer text-white px-6 py-1.5' onClick={addGalleryArea}><span>{icon}</span></button>
        </div>
      </div>

      {/* PORTFOLIO */}
      <div className='w-full border-t border-b border-gray-200'>
        <div className="bg-white  mx-auto max-w-2xl py-16 px-2 sm:py-32 lg:max-w-7xl lg:px-4">
          <div className='w-full flex flex-wrap justify-center grid grid-cols-3 gap-y-8 lg:gap-x-4'>
            <div className="col-span-3 lg:col-span-1">
              <h2 className="mt-2 mt-1 text-3xl font-bold leading-7 text-sky-950/90 text-center lg:text-left">PORTFOLIO</h2>
              <p className="mt-6 leading-7 text-left text-gray-600 text-lg sm:leading-9">
              디앤에프어패럴은 항상 최신 트렌드를 반영하며 기능적이고 실용적인 의류를 제작하기 위해 끊임없이 연구하고 있습니다. 스포츠 애슬레저 분야를 선도하는 기술자들이 높은 퀄리티를 지향하며, 고객사들의 요구에 부응하는 제품을 제공하기 위해 노력하고 있습니다.
              </p>
            </div>

            <div className="flex flex-wrap items-start col-span-3 grid grid-cols-2 gap-y-8 lg:gap-x-4 lg:col-span-2">
              {/* 포트폴리오 :Ballet */}
              <div className='col-span-2 flex flex-wrap relative justify-between sm:col-span-1'>
                <p className='bottom-[-3px] left-5 absolute z-10 text-xl font-semibold'>Ballet</p>
                  <Swiper
                    pagination={{
                      clickable: true,
                    }}
                    modules={[Pagination]}
                    className="portfolio-slide z-9"
                  >
                    <SwiperSlide>
                      <div className='w-full flex flex-wrap py-6 lg:py-4'>
                        <img
                          src='../static/img/portfolio/ballet/pf_ballet1.jpg'
                          alt="dnf_portfolio_img"
                          className="aspect-[3/2] w-full p-3 rounded-[35px] object-cover lg:py-4"
                        />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='w-full flex flex-wrap py-6 lg:py-4'>
                        <img
                          src='../static/img/portfolio/ballet/pf_ballet2.jpg'
                          alt="dnf_portfolio_img"
                          className="aspect-[3/2] w-full p-3 rounded-[35px] object-cover lg:py-4"
                        />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='w-full flex flex-wrap py-6 lg:py-4'>
                        <img
                          src='../static/img/portfolio/ballet/pf_ballet3.jpg'
                          alt="dnf_portfolio_img"
                          className="aspect-[3/2] w-full p-3 rounded-[35px] object-cover lg:py-4"
                        />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='w-full flex flex-wrap py-6 lg:py-4'>
                        <img
                          src='../static/img/portfolio/ballet/pf_ballet4.jpg'
                          alt="dnf_portfolio_img"
                          className="aspect-[3/2] w-full p-3 rounded-[35px] object-cover lg:py-4"
                        />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='w-full flex flex-wrap py-6 lg:py-4'>
                        <img
                          src='../static/img/portfolio/ballet/pf_ballet5.jpg'
                          alt="dnf_portfolio_img"
                          className="aspect-[3/2] w-full p-3 rounded-[35px] object-cover lg:py-4"
                        />
                      </div>
                    </SwiperSlide>
                  </Swiper>
              </div>

              {/* 포트폴리오 :Korean dance */}
              <div className='col-span-2 flex flex-wrap relative justify-between sm:col-span-1'>
                <p className='bottom-[-3px] left-5 absolute z-10 text-xl font-semibold'>Korean dance</p>
                  <Swiper
                    pagination={{
                      clickable: true,
                    }}
                    modules={[Pagination]}
                    className="portfolio-slide z-9"
                  >
                    <SwiperSlide>
                      <div className='w-full flex flex-wrap py-6 lg:py-4'>
                        <img
                          src='../static/img/portfolio/k-dance/pf_k-dance1.jpg'
                          alt="dnf_portfolio_img"
                          className="aspect-[3/2] w-full p-3 rounded-[35px] object-cover lg:py-4"
                        />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='w-full flex flex-wrap py-6 lg:py-4'>
                        <img
                          src='../static/img/portfolio/k-dance/pf_k-dance2.jpg'
                          alt="dnf_portfolio_img"
                          className="aspect-[3/2] w-full p-3 rounded-[35px] object-cover lg:py-4"
                        />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='w-full flex flex-wrap py-6 lg:py-4'>
                        <img
                          src='../static/img/portfolio/k-dance/pf_k-dance3.jpg'
                          alt="dnf_portfolio_img"
                          className="aspect-[3/2] w-full p-3 rounded-[35px] object-cover lg:py-4"
                        />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='w-full flex flex-wrap py-6 lg:py-4'>
                        <img
                          src='../static/img/portfolio/k-dance/pf_k-dance4.jpg'
                          alt="dnf_portfolio_img"
                          className="aspect-[3/2] w-full p-3 rounded-[35px] object-cover lg:py-4"
                        />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='w-full flex flex-wrap py-6 lg:py-4'>
                        <img
                          src='../static/img/portfolio/k-dance/pf_k-dance5.jpg'
                          alt="dnf_portfolio_img"
                          className="aspect-[3/2] w-full p-3 rounded-[35px] object-cover lg:py-4"
                        />
                      </div>
                    </SwiperSlide>
                  </Swiper>
              </div>

              {/* 포트폴리오 :Polewear */}
              <div className='col-span-2 flex flex-wrap relative justify-between sm:col-span-1'>
                <p className='bottom-[-3px] left-5 absolute z-10 text-xl font-semibold'>Polewear</p>
                  <Swiper
                    pagination={{
                      clickable: true,
                    }}
                    modules={[Pagination]}
                    className="portfolio-slide z-9"
                  >
                    <SwiperSlide>
                      <div className='w-full flex flex-wrap py-6 lg:py-4'>
                        <img
                          src='../static/img/portfolio/Polewear/pf_pole1.jpg'
                          alt="dnf_portfolio_img"
                          className="aspect-[3/2] w-full p-3 rounded-[35px] object-cover lg:py-4"
                        />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='w-full flex flex-wrap py-6 lg:py-4'>
                        <img
                          src='../static/img/portfolio/Polewear/pf_pole2.jpg'
                          alt="dnf_portfolio_img"
                          className="aspect-[3/2] w-full p-3 rounded-[35px] object-cover lg:py-4"
                        />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='w-full flex flex-wrap py-6 lg:py-4'>
                        <img
                          src='../static/img/portfolio/Polewear/pf_pole3.jpg'
                          alt="dnf_portfolio_img"
                          className="aspect-[3/2] w-full p-3 rounded-[35px] object-cover lg:py-4"
                        />
                      </div>
                    </SwiperSlide>
                  </Swiper>
              </div>

              {/* 포트폴리오 :Yoga & Pilates */}
              <div className='col-span-2 flex flex-wrap relative justify-between sm:col-span-1'>
                <p className='bottom-[-3px] left-5 absolute z-10 text-xl font-semibold'>Yoga & Pilates</p>
                  <Swiper
                    pagination={{
                      clickable: true,
                    }}
                    modules={[Pagination]}
                    className="portfolio-slide z-9"
                  >
                    <SwiperSlide>
                      <div className='w-full flex flex-wrap py-6 lg:py-4'>
                        <img
                          src='../static/img/portfolio/yogaplia/pf_yogaplia1.jpg'
                          alt="dnf_portfolio_img"
                          className="aspect-[3/2] w-full p-3 rounded-[35px] object-cover lg:py-4"
                        />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='w-full flex flex-wrap py-6 lg:py-4'>
                        <img
                          src='../static/img/portfolio/yogaplia/pf_yogaplia2.jpg'
                          alt="dnf_portfolio_img"
                          className="aspect-[3/2] w-full p-3 rounded-[35px] object-cover lg:py-4"
                        />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='w-full flex flex-wrap py-6 lg:py-4'>
                        <img
                          src='../static/img/portfolio/yogaplia/pf_yogaplia3.jpg'
                          alt="dnf_portfolio_img"
                          className="aspect-[3/2] w-full p-3 rounded-[35px] object-cover lg:py-4"
                        />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='w-full flex flex-wrap py-6 lg:py-4'>
                        <img
                          src='../static/img/portfolio/yogaplia/pf_yogaplia4.jpg?ver=2'
                          alt="dnf_portfolio_img"
                          className="aspect-[3/2] w-full p-3 rounded-[35px] object-cover lg:py-4"
                        />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='w-full flex flex-wrap py-6 lg:py-4'>
                        <img
                          src='../static/img/portfolio/yogaplia/pf_yogaplia5.jpg?ver=2'
                          alt="dnf_portfolio_img"
                          className="aspect-[3/2] w-full p-3 rounded-[35px] object-cover lg:py-4"
                        />
                      </div>
                    </SwiperSlide>
                  </Swiper>
              </div>

              {/* 포트폴리오 :Swimsuit */}
              <div className='col-span-2 flex flex-wrap relative justify-between sm:col-span-1'>
                <p className='bottom-[-3px] left-5 absolute z-10 text-xl font-semibold'>Swimsuit</p>
                <div className='w-full flex flex-wrap py-6 lg:py-4'>
                    <img
                      src='../static/img/portfolio/coming_soon.jpg'
                      alt="dnf_portfolio_img"
                      className="aspect-[3/2] w-full p-3 rounded-[35px] object-cover lg:py-4"
                    />
                  </div>
              </div>

              {/* 포트폴리오 :Resort & Beachwear */}
              <div className='col-span-2 flex flex-wrap relative justify-between sm:col-span-1'>
                <p className='bottom-[-3px] left-5 absolute z-10 text-xl font-semibold'>Resort & Beachwear</p>
                  <div className='w-full flex flex-wrap py-6 lg:py-4'>
                    <img
                      src='../static/img/portfolio/coming_soon.jpg'
                      alt="dnf_portfolio_img"
                      className="aspect-[3/2] w-full p-3 rounded-[35px] object-cover lg:py-4"
                    />
                  </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      

      {/* 협력사 로고 노출 영역 */}
      <div className="bg-white relative mx-auto items-center max-w-7xl py-16 px-2 sm:py-32 lg:px-4">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold text-sky-950/90 text-gray-900 sm:text-4xl">With us </h2>
          <p className='text-base font-bold mt-6 sm:text-xl'>디앤에프어패럴은 고객사의 성공을 진심으로 응원합니다.</p>
        </div>
        {/* 웹 로고 */}
        <div className='w-full max-w-7xl hidden justify-center mt-8 sm:flex'>
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Autoplay, Pagination]}
            className="withus-slide"
          >
            <SwiperSlide className='p-3 mb-16'>
              <div className='w-full hidden justify-between items-center sm:flex'>
                <div className='flex justify-center items-center sm:w-40 lg:w-48 px-2'>
                  <img
                      src='../static/img/with_logo/pota_logo.jpg'
                      alt="dnf_with_logo_img"
                      className="w-full"
                  />
                </div>
                <div className='flex justify-center items-center sm:w-40 lg:w-48 px-2'>
                  <img
                    src='../static/img/with_logo/slow_campo_logo.jpg'
                    alt="dnf_with_logo_img"
                    className="w-full"
                  />
                </div>
                <div className='flex justify-center items-center sm:w-40 lg:w-48 px-2'>
                  <img
                    src='../static/img/with_logo/messiah_logo.jpg'
                    alt="dnf_with_logo_img"
                    className="w-full"
                  />
                </div>
                <div className='flex justify-center items-center sm:w-40 lg:w-48 px-2'>
                  <img
                    src='../static/img/with_logo/leponem_logo.jpg'
                    alt="dnf_with_logo_img"
                    className="w-full"
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className='p-3 mb-16'>
              <div className='w-full hidden justify-between items-center sm:flex'>
                <div className='flex justify-center items-center sm:w-40 lg:w-48 px-2'>
                  <img
                    src='../static/img/with_logo/mignon_logo.jpg'
                    alt="dnf_with_logo_img"
                    className="w-full"
                  />
                </div>
                <div className='flex justify-center items-center sm:w-40 lg:w-48 px-2'>
                  <img
                    src='../static/img/with_logo/mona_ballet_logo.jpg'
                    alt="dnf_with_logo_img"
                    className="w-full"
                  />
                </div>
                <div className='flex justify-center items-center sm:w-40 lg:w-48 px-2'>
                  <img
                    src='../static/img/with_logo/lalafit_logo.jpg'
                    alt="dnf_with_logo_img"
                    className="w-full"
                  />
                </div>
                <div className='flex justify-center items-center sm:w-40 lg:w-48 px-2'>
                  <img
                    src='../static/img/with_logo/yourshop_logo.jpg'
                    alt="dnf_with_logo_img"
                    className="w-full"
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className='p-3 mb-16'>
              <div className='w-full hidden justify-between items-center sm:flex'>
                <div className='flex justify-center items-center sm:w-40 lg:w-48 px-2'>
                  <img
                    src='../static/img/with_logo/sungil_logo.jpg'
                    alt="dnf_with_logo_img"
                    className="w-full"
                  />
                </div>
                <div className='flex justify-center items-center sm:w-40 lg:w-48 px-2'>
                  <img
                    src='../static/img/with_logo/adelia_new_logo.jpg'
                    alt="dnf_with_logo_img"
                    className="w-full"
                  />
                </div>
                <div className='flex justify-center items-center sm:w-40 lg:w-48 px-2'>
                  <img
                    src='../static/img/with_logo/jaeil_logo.jpg'
                    alt="dnf_with_logo_img"
                    className="w-full"
                  />
                </div>
                <div className='flex justify-center items-center sm:w-40 lg:w-48 px-2'></div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>

        {/* 모바일 로고 */}
        <div className='w-full max-w-7xl flex justify-center mt-8 sm:hidden'>
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Autoplay, Pagination]}
            className="withus-slide"
          >
            <SwiperSlide className='p-3 mb-12'>
              <div className='w-full flex justify-between items-center sm:hidden'>
                <div className='w-40 justify-center items-center px-2'>
                  <img
                    src='../static/img/with_logo/pota_logo.jpg'
                    alt="dnf_with_logo_img"
                    className="w-full"
                  />
                </div>
                <div className='w-40 justify-center items-center px-2'>
                  <img
                    src='../static/img/with_logo/slow_campo_logo.jpg'
                    alt="dnf_with_logo_img"
                    className="w-full"
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className='p-3 mb-12'>
              <div className='w-full flex justify-between items-center sm:hidden'>
                <div className='w-40 justify-center items-center px-2'>
                <img
                    src='../static/img/with_logo/messiah_logo.jpg'
                    alt="dnf_with_logo_img"
                    className="w-full"
                  />
                </div>
                <div className='w-40 justify-center items-center px-2'>
                  <img
                    src='../static/img/with_logo/leponem_logo.jpg'
                    alt="dnf_with_logo_img"
                    className="w-full"
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className='p-3 mb-12'>
              <div className='w-full flex justify-between items-center sm:hidden'>
                <div className='w-40 justify-center items-center px-2'>
                  <img
                    src='../static/img/with_logo/mignon_logo.jpg'
                    alt="dnf_with_logo_img"
                    className="w-full"
                  />
                </div>
                <div className='w-40 justify-center items-center px-2'>
                  <img
                    src='../static/img/with_logo/mona_ballet_logo.jpg'
                    alt="dnf_with_logo_img"
                    className="w-full"
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className='p-3 mb-12'>
              <div className='w-full flex justify-between items-center sm:hidden'>
                
                <div className='w-40 justify-center items-center px-2'>
                  <img
                    src='../static/img/with_logo/lalafit_logo.jpg'
                    alt="dnf_with_logo_img"
                    className="w-full"
                  />
                </div>
                <div className='w-40 justify-center items-center px-2'>
                  <img
                    src='../static/img/with_logo/yourshop_logo.jpg'
                    alt="dnf_with_logo_img"
                    className="w-full"
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className='p-3 mb-12'>
              <div className='w-full flex justify-between items-center sm:hidden'>
                <div className='w-40 justify-center items-center px-2'>
                  <img
                    src='../static/img/with_logo/sungil_logo.jpg'
                    alt="dnf_with_logo_img"
                    className="w-full"
                  />
                </div>
                <div className='w-40 justify-center items-center px-2'>
                  <img
                    src='../static/img/with_logo/adelia_new_logo.jpg'
                    alt="dnf_with_logo_img"
                    className="w-full"
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className='p-3 mb-12'>
              <div className='w-full flex justify-between items-center sm:hidden'>
                <div className='w-40 justify-center items-center px-2'>
                  <img
                    src='../static/img/with_logo/jaeil_logo.jpg'
                    alt="dnf_with_logo_img"
                    className="w-full"
                  />
                </div>
                <div className='w-40 justify-center items-center px-2'></div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    
      {/* FAQ 영역 */}
      <div className="bg-white mx-auto max-w-7xl px-6 py-16 px-3 sm:py-32 lg:px-4">
        <div className="mx-auto max-w-6xl divide-y divide-gray-900/10">
          <h2 className="text-3xl font-bold leading-10 text-sky-950/90 sm:text-4xl">FAQ</h2>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">{faq.question}</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <OutlineMinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <OutlinePlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div> 
      </div>

      <footer className="bg-sky-950/90 px-6 pt-24 pb-12 px-2 lg:px-4">
        <div className="mx-auto max-w-7xl flex flex-wrap overflow-hidden text-center sm:p-5 lg:text-left">
          <div className="w-full flex flex-wrap justify-center lg:justify-start lg:w-1/2">
            <div className='dnf-logo'> </div>
            <div className='w-full mt-5 text-sm text-gray-300 leading-7'>
              디앤에프어패럴 | 대표 : 김성준
              <br />
              제휴/협업 문의 : sghdancefarm@gmail.com
              <br />
              <br />
              <br />
              <b>디앤에프어패럴</b>
              <br />
              부산광역시 부산진구 신암로 93, 영신빌딩 6층
              <br />
              (1호선 범내골역)
            </div>                  
          </div>
          <div className="mt-8 w-full h-fit flex flex-wrap justify-center text-center lg:mt-0 lg:text-right lg:justify-end lg:w-1/2">
            <a href='https://www.instagram.com/dnf__apparel/'>
              <div className='dnf-insta-icon mx-3 cursor-pointer'></div>
            </a>
            <a href='https://youtube.com/@shallwedancefarm'>
              <div className='dnf-youtube-icon mx-3 cursor-pointer'></div>
            </a>
            <div className='dnf-mail-icon ml-3 cursor-pointer' onClick={mail_copy_event}></div>
            
            <div className='w-full flex justify-center mt-6 font-medium lg:justify-end'>
              <div className="w-64 flex sm:w-60">
                  <select onChange={handleSelectChange} className="custom-select mt-2 appearance-none block w-full rounded-md outline-none border-0 py-2 pl-3 pr-7 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-indigo-600/75 sm:text-sm sm:leading-6">
                      <option value="">디앤에프어패럴 패밀리 사이트</option>
                      <option value="https://www.msdance.kr/">메시아댄스웨어</option>
                      <option value="https://www.msculturestudio.com/">메시아컬쳐스튜디오</option>
                      <option value="https://www.umpilates.com/">유엠필라테스</option>
                      <option value="https://www.umclass.com/">움클래스</option>
                  </select>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-3 p-5 lg:mt-10">
          <p className="text-center text-xs leading-5 text-gray-300">
            &copy; COPYRIGHT 디앤에프어패럴 All rights reserved. <br /> 본 사이트 내 모든 이미지 컨텐츠 등은 저작권법 제 4조에 의한 저작물로써 소유권은 디앤에프어패럴에게 있으며, 무단 도용 시 법적인 제재를 받을 수 있습니다.
          </p>
        </div>
        
      </footer>

      {/* scroll down icon */}
      <div className={scrollPosition > 30 ? 'hidden' : 'block'}>
        <div className='main-scroll-icon animate-bounce w-28 h-10 z-20 fixed left-[35%] sm:w-32 sm:h-12 sm:left-[42%] lg:left-[47%] bottom-0'></div>
      </div>
      
      {/* kakao icon */}
      <div className={scrollPosition > 200 ? 'fixed z-50 cursor-pointer drop-shadow-xl bg-white border border-gray-300 py-2 px-3 rounded-[20px] bottom-8 right-8 sm:px-3.5 sm:bottom-12 sm:right-8' : 'hidden'}>
        <a href='http://pf.kakao.com/_xmhdDxj/chat'>
          <div className = 'flex justify-center'>
              <div className='dnf-kakao-icon w-9 h-9 sm:w-11 sm:h-11'></div>
          </div>
          <p className='text-sky-950/90 text-sm text-center font-semibold'>문의하기</p>
        </a>
      </div>

      {/* 메일 주소 복사 성공 알림창 */}
      <div
        aria-live="assertive"
        className="hidden pointer-events-none fixed z-50 inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
        ref={dnfMailCopyEvent}
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-sky-950/90 shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <OutlineCheckCircleIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-white">이메일 복사가 완료되었습니다.</p>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>

      
    </div>
    

  )
}
